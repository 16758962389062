@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

  
   background-image:linear-gradient(
    to left,
    rgb(255, 255, 255),
    rgb(255, 255, 255)
  );

  
}
